// src/components/Profile/ProfileInput.js
import React from 'react';
import { TextField } from '@mui/material';
import PhoneInput from './PhoneInput';

const ProfileInput = ({ label, name, value, onChange, onBlur, error, helperText }) => {
  if (name === 'phone') {
    return <PhoneInput value={value} onChange={onChange} />;
  }

  return (
    <TextField
      label={label}
      name={name}
      value={value}
      onChange={onChange}
      fullWidth
      margin="normal"
      onBlur={onBlur}
      error={error}
      helperText={helperText}
    />
  );
};

export default ProfileInput;
