import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';

const DarkModeToggle = ({ onToggleDarkMode, darkMode, iconSize }) => {
  return (
    <Tooltip title={darkMode ? 'Switch to light mode' : 'Switch to dark mode'}>
      <IconButton
        color="inherit"
        onClick={onToggleDarkMode}
      >
        {darkMode ? <Brightness7Icon style={{ fontSize: 45 }} /> : <Brightness4Icon style={{ fontSize: 45 }} />}
      </IconButton>
    </Tooltip>
  );
};

export default DarkModeToggle;
