// src/components/Connect/Connect.js

import React from 'react';
import { Typography, Grid, IconButton, Box, Slide } from '@mui/material';
import { FaDiscord, FaTelegramPlane, FaYoutube, FaInstagram } from 'react-icons/fa';
import { keyframes } from '@emotion/react';

const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
`;

const Connect = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'top',
        minHeight: '100vh',
        background: (theme) =>
          `linear-gradient(135deg, ${theme.palette.primary.main} 20%, ${theme.palette.primary.light} 100%)`,
        pt: 10,
      }}
    >
      <Typography variant="h3" align="center" gutterBottom sx={{ paddingBottom: '5rem' }}>
        Connect with us on social media
      </Typography>
      <Grid container spacing={6} justifyContent="center">
        {[
          { href: 'https://discord.com/', icon: <FaDiscord size="2.5em" />, color: 'secondary' },
          { href: 'https://telegram.org/', icon: <FaTelegramPlane size="2.5em" />, color: 'secondary' },
          { href: 'https://www.youtube.com/', icon: <FaYoutube size="2.5em" />, color: 'error' },
          { href: 'https://www.instagram.com/', icon: <FaInstagram size="2.5em" />, color: 'error' },
        ].map((item, index) => (
          <Grid item key={item.href}>
            <Slide direction="up" in={true} mountOnEnter unmountOnExit timeout={400 + index * 200}>
              <IconButton
                sx={{
                  color: item.color,
                  fontSize: '2.5em',
                  animation: `${pulse} 2s infinite`,
                }}
                href={item.href}
                target="_blank"
                rel="noopener noreferrer"
              >
                {item.icon}
              </IconButton>
            </Slide>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Connect;
