// src/components/Book/BookDetails.js
import React from 'react';
import { Typography, Button } from '@mui/material';

const BookDetails = ({
  title,
  author,
  shortSummary,
  showSummary,
  onToggleSummary,
}) => (
  <div>
    <Typography variant="h5" component="div">
      {title}
    </Typography>
    <Typography variant="subtitle1">By: {author}</Typography>
    {showSummary && <Typography variant="body2">{shortSummary}</Typography>}
    <Button onClick={onToggleSummary}>
      {showSummary ? 'Hide Summary' : 'Show Summary'}
    </Button>
  </div>
);

export default BookDetails;
