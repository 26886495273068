import React from 'react';
import { Avatar, Button, FormControl, Box } from '@mui/material';

const AvatarUpload = ({ avatar, onAvatarChange }) => {
  const handleAvatarChange = (e) => {
    onAvatarChange(URL.createObjectURL(e.target.files[0]));
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Avatar
        src={avatar}
        alt="User avatar"
        sx={{
          width: { xs: 150, sm: 200 },
          height: { xs: 150, sm: 200 },
          marginBottom: 1,
        }}
      />
      <FormControl margin="normal">
        <input
          type="file"
          id="avatar-upload"
          onChange={handleAvatarChange}
          accept="image/*"
          hidden
        />
        <label htmlFor="avatar-upload">
          <Button variant="contained" component="span">
            Choose File
          </Button>
        </label>
      </FormControl>
    </Box>
  );
};

export default AvatarUpload;
