// src/components/Leaderboard/Leaderboard.js

import React from 'react';
import {
  Typography,
  Container,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Slide,
  Avatar,
} from '@mui/material';
import { GiTrophy } from 'react-icons/gi';
import { MdStars } from 'react-icons/md';

const mockData = [
  { name: 'John Doe', points: 120 },
  { name: 'Jane Smith', points: 100 },
  { name: 'Sarah Johnson', points: 90 },
  { name: 'Michael Brown', points: 80 },
  { name: 'William Lee', points: 70 },
];

const Leaderboard = () => {
  const rankColors = ['#FFD700', '#C0C0C0', '#CD7F32'];


  const renderRankIcon = (index) => {
    if (index < 3) {
      return <MdStars color={rankColors[index]} />;
    }
    return index + 1;
  };

  return (
    <Container>
      <Box
        sx={{
          minHeight: '100vh',
          paddingTop: 4,
          paddingBottom: 4,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        }}
      >
        <Typography variant="h3" component="h1" gutterBottom align="center">
          <GiTrophy />
          &nbsp; Leaderboard
        </Typography>
        <Typography variant="h6" gutterBottom align="center">
          Top Readers on Our Platform
        </Typography>
        <TableContainer component={Paper} sx={{ width: '80%', maxWidth: 800 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Rank</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Points</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {mockData.map((row, index) => (
                <Slide direction="up" in={true} mountOnEnter unmountOnExit timeout={400 + index * 100} key={row.name}>
                  <TableRow>
                    <TableCell>
                      <Avatar variant="rounded" sx={{ marginRight: 1, backgroundColor: '#87CEFA' }}>
                        {renderRankIcon(index)}
                      </Avatar>
                    </TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.points}</TableCell>
                  </TableRow>
                </Slide>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Container>
  );
};

export default Leaderboard;
