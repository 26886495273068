// src/components/Book/Book.js
import React, { useState } from 'react';
import { Card, CardContent } from '@mui/material';
import CardActionAreaLink from '../CardActionAreaLink/CardActionAreaLink';
import BookCover from './BookCover';
import BookDetails from './BookDetails';
import './Book.css';

const Book = ({
  id,
  title,
  author,
  shortSummary, // updated prop name
  isbn,
  cover, // new prop
  genres, // new prop
  rating, // new prop
}) => {
  const [showSummary, setShowSummary] = useState(false);

  const handleToggleSummary = () => {
    setShowSummary(!showSummary);
  };

  return (
    <Card
      sx={{
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)',
        '&:hover': {
          boxShadow: '0 8px 12px rgba(0, 0, 0, 0.15), 0 2px 6px rgba(0, 0, 0, 0.1)',
        },
        transition: 'box-shadow 0.25s ease-in-out',
      }}
    >
      <CardActionAreaLink to={`/summary/${id}`}>
        <CardContent>
          <div className="book-info">
            <BookCover isbn={isbn} title={title} cover={cover} />
            <div className="book-details-wrapper">
              <BookDetails
                title={title}
                author={author}
                shortSummary={shortSummary} // updated prop name
                showSummary={showSummary}
                genres={genres} // new prop
                rating={rating} // new prop
                onToggleSummary={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleToggleSummary();
                }}
              />
            </div>
          </div>
        </CardContent>
      </CardActionAreaLink>
    </Card>
  );
};

export default Book;
