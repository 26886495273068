// src/components/CardActionAreaLink/CardActionAreaLink.js

import React from 'react';
import { CardActionArea } from '@mui/material';
import { Link } from 'react-router-dom';

const CardActionAreaLink = ({ to, children, ...otherProps }) => {
  return (
    <CardActionArea component={Link} to={to} {...otherProps}>
      {children}
    </CardActionArea>
  );
};

export default CardActionAreaLink;
