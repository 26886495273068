// src/components/SummaryPage/SummaryContent.js

import React from 'react';
import { Typography } from '@mui/material';

const SummaryContent = ({ sectionTitle, sectionContent }) => {
  const inlineStyle = {
    whiteSpace: 'pre-wrap',
    marginBottom: '16px',
  };

  const titleStyle = {
    fontWeight: 'bold',
    color: '#3f51b5',
    marginBottom: '8px',
  };

  const tabStyle = {
    marginLeft: '2em',
  };

  const formattedContent = sectionContent.split('\n').map((line, index) => (
    <React.Fragment key={index}>
      <span style={tabStyle} />
      {line}
      <br />
    </React.Fragment>
  ));

  return (
    <div>
      <Typography variant="h6" component="div" gutterBottom style={titleStyle}>
        {sectionTitle}
      </Typography>
      <Typography variant="body1" component="div" gutterBottom style={inlineStyle}>
        {formattedContent}
      </Typography>
    </div>
  );
};

export default SummaryContent;
