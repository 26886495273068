import React from 'react';
import Book from '../Book/Book';
import { Grid } from '@mui/material';
import './BookList.css';

const BookList = ({ books }) => {
  return (
    <Grid container spacing={2} className="book-list">
      {books.map((book) => {
        const { id, title, author, shortSummary, isbn } = book;
        return (
          <Grid item key={id} xs={12} sm={6} md={6} className="book-item">
            <Book id={id} title={title} author={author} shortSummary={shortSummary} isbn={isbn} />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default BookList;
