// src/components/Shimmer/Shimmer.js

import React from 'react';
import './Shimmer.css';

const Shimmer = ({ width, height }) => {
  return (
    <div
      className="shimmer"
      style={{ width: width || '100%', height: height || '100%' }}
    ></div>
  );
};

export default Shimmer;
