// src/components/Profile/ProfileForm.js
import React from 'react';
import { Box, Button, Grid } from '@mui/material';
import ProfileInput from './ProfileInput';
import AvatarUpload from './AvatarUpload';
import PhoneInput from './PhoneInput';

const ProfileForm = ({
  values,
  onInputChange,
  onPhoneChange,
  onAvatarChange,
  onBlur,
  handleSubmit,
  handleAvailability,
}) => {
  return (
    <form onSubmit={handleSubmit} className="form-container">
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <AvatarUpload avatar={values.avatar} onAvatarChange={onAvatarChange} />
        </Grid>
        <Grid item xs={12} md={8}>
          {[
            { label: 'Name', name: 'name' },
            {
              label: 'Handle',
              name: 'handle',
              onBlur,
              error: !handleAvailability,
              helperText: handleAvailability
                ? 'Your unique handle (cannot be changed after selecting)'
                : 'Handle not available',
            },
            { label: 'Email', name: 'email' },
          ].map((field) => (
            <ProfileInput key={field.name} {...field} value={values[field.name]} onChange={onInputChange} />
          ))}
          <PhoneInput value={values.phone} onChange={onPhoneChange} />
        </Grid>
      </Grid>
      <Box mt={2} sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <Button variant="contained" color="primary" type="submit">
          Update Profile
        </Button>
      </Box>
    </form>
  );
};

export default ProfileForm;
