// src/components/SummaryPage/SummaryPage.js
/* Long form summary.
import React from 'react';
import { useParams } from 'react-router-dom';
import booksData from './../../data/books.json';
import { Box, Typography, Paper } from '@mui/material';

const SummaryPage = () => {
  const { id } = useParams();
  const book = booksData.find((b) => b.id === id);

  if (!book) {
    return <Typography variant="h4">Book not found.</Typography>;
  }

  return (
    <Box className="summary-container">
      <Typography variant="h4">{book.title}</Typography>
      <Typography variant="subtitle1" gutterBottom>By {book.author}</Typography>
      {book.summary.map((section) => (
        <Paper key={section.title} elevation={2} sx={{ padding: 2, marginBottom: 2 }}>
          <Typography variant="h6" component="div" gutterBottom>
            {section.title}
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            {section.content.split('\n').map((line, index) => (
              <React.Fragment key={index}>
                <span style={{ marginLeft: '2em' }} />
                {line}
                <br />
              </React.Fragment>
            ))}
          </Typography>
        </Paper>
      ))}
    </Box>
  );
};

export default SummaryPage; 
*/

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import booksData from './../../data/books.json';
import { Box, Typography } from '@mui/material';
import { useSwipeable } from 'react-swipeable';
import Header from './Header';
import SummarySection from './SummarySection';
import SectionNavigation from './SectionNavigation';
import SectionSelect from './SectionSelect';

const SummaryPage = () => {
  const { id } = useParams();
  const book = booksData.find((b) => b.id === id);

  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
  const [showContent, setShowContent] = useState(true);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Change to 'auto' for a faster scroll
    });
  };

  useEffect(() => {
    setShowContent(false);
    const timer = setTimeout(() => {
      setShowContent(true);
      scrollToTop();
    }, 100); // Reduce the timeout value for a faster transition
    return () => clearTimeout(timer);
  }, [currentSectionIndex]);

  const handleNextSection = () => {
    if (currentSectionIndex < sectionKeys.length - 1) {
      setCurrentSectionIndex(currentSectionIndex + 1);
    }
  };

  const handlePreviousSection = () => {
    if (currentSectionIndex > 0) {
      setCurrentSectionIndex(currentSectionIndex - 1);
    }
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: handleNextSection,
    onSwipedRight: handlePreviousSection,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  if (!book) {
    return <Typography variant="h4">Book not found.</Typography>;
  }

  const sectionKeys = book.summary.map(section => section.title);

  const handleSectionClick = (index) => {
    setCurrentSectionIndex(index);
  };

  const currentSection = book.summary[currentSectionIndex];

  return (
    <Box className="summary-container">
      <Header title={book.title} author={book.author} />
      <SectionSelect
        sectionKeys={sectionKeys}
        currentSectionIndex={currentSectionIndex}
        handleSectionChange={handleSectionClick}
      />
      <div {...swipeHandlers}>
        <SummarySection title={currentSection.title} content={currentSection.content} show={showContent} />
      </div>
      <SectionNavigation
        onPreviousSection={handlePreviousSection}
        onNextSection={handleNextSection}
        isFirstSection={currentSectionIndex === 0}
        isLastSection={currentSectionIndex === sectionKeys.length - 1}
      />
      {/* <Comments id="comments-section" /> */}
    </Box>
  );
};

export default SummaryPage;
