// src/App.js

import React, { useState, useMemo } from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import { Container } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import getTheme from './theme';
import Header from './components/Header/Header';
import HomePage from './pages/HomePage/HomePage';
import SummaryPage from './components/SummaryPage/SummaryPage';
import About from './pages/About/About';
import Connect from './pages/Connect/Connect';
import Profile from './pages/Profile/Profile';
import SupportUs from './pages/SupportUs/SupportUs';
import SmartMembership from './pages/SmartMembership/SmartMembership';
import Leaderboard from './pages/Leaderboard/Leaderboard';
import LoginPage from './pages/Login/LoginPage';

const App = () => {
  const [darkMode, setDarkMode] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);

  const handleLogin = (username, password) => {
    // Perform your authentication logic here
    if (username === 'admin' && password === 'admin') {
      setLoggedIn(true);
    }
  };

  const handleLogout = () => {
    setLoggedIn(false);
  };

  const theme = useMemo(() => getTheme(darkMode ? 'dark' : 'light'), [darkMode]);

  const handleToggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Header onToggleDarkMode={handleToggleDarkMode} darkMode={darkMode} loggedIn={loggedIn} onLogout={handleLogout} />
        <Container>
        <Routes>
          <Route path="/" element={<HomePage />} exact />
          <Route path="/summary/:id" element={<SummaryPage />} />
          <Route path="/connect" element={<Connect />} />
          <Route path="/about" element={<About />} />
          <Route path="/support" element={<SupportUs />} />
          <Route path="/leaderboard" element={<Leaderboard />} />
          <Route path="/smart-membership" element={<SmartMembership />} />
          <Route path="/login" element={<LoginPage onLogin={handleLogin} />} />
          <Route path="/profile" element={<Profile />} />
        </Routes>
        </Container>
      </Router>
    </ThemeProvider>
  );
};

export default App;
