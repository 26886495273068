// src/components/Profile/PhoneInput.js
import React from 'react';
import { TextField, Grid, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import countryCodes from './countryCodes';

const PhoneInput = ({ value, onChange }) => {
  return (
    <FormControl fullWidth margin="normal">
      <InputLabel shrink>Phone</InputLabel>
      <Grid container spacing={1} alignItems="flex-end">
        <Grid item xs={3}>
          <Select
            value={value.substring(0, value.indexOf(' '))}
            onChange={onChange}
            name="countryCode"
            fullWidth
          >
            {countryCodes.map((country) => (
              <MenuItem key={country.code} value={country.code}>
                {country.code} ({country.name})
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={9}>
          <TextField value={value.substring(value.indexOf(' '))} onChange={onChange} name="phone" fullWidth />
        </Grid>
      </Grid>
    </FormControl>
  );
};

export default PhoneInput;
