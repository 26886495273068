// src/components/FormInput/FormInput.js

import React from 'react';
import { TextField } from '@mui/material';

const FormInput = ({ label, type, name, value, error, onChange }) => {
  return (
    <TextField
      label={label}
      type={type}
      name={name}
      variant="outlined"
      value={value}
      error={Boolean(error)}
      helperText={error}
      onChange={onChange}
      sx={{ marginBottom: 2, width: '100%' }}
    />
  );
};

export default FormInput;
