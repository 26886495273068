// src/pages/Profile/Profile.js
import React, { useState } from 'react';
import { Typography, Box } from '@mui/material';
import ProfileForm from '../../components/Profile/ProfileForm';

const Profile = () => {
  const [values, setValues] = useState({
    name: 'John Doe',
    handle: 'johndoe',
    email: 'john.doe@example.com',
    phone: '+1 234 567 8901',
    avatar: '',
  });

  const [handleAvailable, setHandleAvailable] = useState(true);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const handlePhoneChange = (e) => {
    const { name, value } = e.target;
    if (name === 'countryCode') {
      const phoneNumber = values.phone.substring(values.phone.indexOf(' '));
      setValues({ ...values, phone: value + phoneNumber });
    } else {
      const countryCode = values.phone.substring(0, values.phone.indexOf(' '));
      setValues({ ...values, phone: countryCode + value });
    }
  };

  const handleAvatarChange = (url) => {
    setValues({ ...values, avatar: url });
  };

  const handleBlur = (e) => {
    // Add your logic here to check handle availability
    // Set handleAvailable to true or false based on the result
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    alert('Profile updated successfully!');
  };

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Your Profile
      </Typography>
      <ProfileForm
        values={values}
        onInputChange={handleInputChange}
        onPhoneChange={handlePhoneChange}
        onAvatarChange={handleAvatarChange}
        onBlur={handleBlur}
        handleSubmit={handleSubmit}
        handleAvailability={handleAvailable}
      />
    </Box>
  );
};

export default Profile;
