import React, { useState } from 'react';
import SearchBar from '../../components/SearchBar/SearchBar';
import BookList from '../../components/BookList/BookList';
import booksData from './../../data/books.json';
import { Grid, Button } from '@mui/material';

const TranslationButton = ({ language, url, translation }) => {
  const openTranslatedSite = () => {
    window.open(url, "_blank");
  };

  return (
    <Button variant="contained" color="primary" onClick={openTranslatedSite}>
      {translation}
    </Button>
  );
};

const HomePage = () => {
  const [books] = useState(booksData);
  const [searchValue, setSearchValue] = useState('');

  const filteredBooks = books.filter((book) => {
    return (
      book.title.toLowerCase().includes(searchValue.toLowerCase()) ||
      book.author.toLowerCase().includes(searchValue.toLowerCase())
    );
  });

  return (
    <div>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12}>
          <SearchBar searchValue={searchValue} setSearchValue={setSearchValue} />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2} justifyContent="center">
            <Grid item>
              <TranslationButton
                language="हिंदी"
                url="https://longform-intellectcorner-com.translate.goog/?_x_tr_sl=en&_x_tr_tl=hi&_x_tr_hl=en&_x_tr_pto=wapp"
                translation="हिंदी में पढ़ें"
              />
            </Grid>
            <Grid item>
              <TranslationButton
                language="Gujarati"
                url="https://longform-intellectcorner-com.translate.goog/?_x_tr_sl=en&_x_tr_tl=gu&_x_tr_hl=en&_x_tr_pto=wapp"
                translation="ગુજરાતીમાં વાંચો"
              />
            </Grid>
            <Grid item>
              <TranslationButton
                language="Tamil"
                url="https://longform-intellectcorner-com.translate.goog/?_x_tr_sl=en&_x_tr_tl=ta&_x_tr_hl=en&_x_tr_pto=wapp"
                translation="தமிழில் படிக்கவும்"
              />
            </Grid>
            <Grid item>
              <TranslationButton
                language="Spanish"
                url="https://longform-intellectcorner-com.translate.goog/?_x_tr_sl=en&_x_tr_tl=es&_x_tr_hl=en&_x_tr_pto=wapp"
                translation="Leer en español"
              />
            </Grid>
            <Grid item>
              <TranslationButton
                language="Chinese"
                url="https://longform-intellectcorner-com.translate.goog/?_x_tr_sl=en&_x_tr_tl=zh-CN&_x_tr_hl=en&_x_tr_pto=wapp"
                translation="用中文阅读"
              />
            </Grid>
            <Grid item>
              <TranslationButton
                language="Arabic"
                url="https://longform-intellectcorner-com.translate.goog/?_x_tr_sl=en&_x_tr_tl=ar&_x_tr_hl=en&_x_tr_pto=wapp"
                translation="اقرأ باللغة العربية"
              />
            </Grid>
            <Grid item>
              <TranslationButton
                language="French"
                url="https://longform-intellectcorner-com.translate.goog/?_x_tr_sl=en&_x_tr_tl=fr&_x_tr_hl=en&_x_tr_pto=wapp"
                translation="Lire en français"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <BookList books={filteredBooks} />
        </Grid>
      </Grid>
    </div>
  );
};

export default HomePage;
