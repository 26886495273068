// src/components/SummaryPage/Header.js

import React from 'react';
import { Typography } from '@mui/material';

const Header = ({ title, author }) => {
  return (
    <>
      <Typography variant="h3" component="div" gutterBottom>
        {title}
      </Typography>
      <Typography variant="h6" gutterBottom>
        By: {author}
      </Typography>
    </>
  );
};

export default Header;
