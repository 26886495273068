// src/components/SupportUs/SupportUs.js

import React from 'react';
import { Typography } from '@mui/material';

const SupportUs = () => {
  return (
    <div>
      <Typography variant="h4" component="div">
        Support Us
      </Typography>
      <Typography variant="body1">
        If you enjoy our content and would like to support us, please consider the following options:
      </Typography>
      <ul>
        <li>
          <Typography variant="body1">
            Share our website with your friends and family.
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            Follow us on social media (check the <strong>Connect</strong> page).
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            Donate via [preferred payment method].
          </Typography>
        </li>
      </ul>
    </div>
  );
};

export default SupportUs;
