// src/components/SmartMembership/SmartMembership.js

import React from 'react';
import { Typography, Button, Container, Box } from '@mui/material';

const SmartMembership = () => {
  return (
    <Container>
      <Box sx={{ textAlign: 'center', marginTop: 4, marginBottom: 4 }}>
        <Typography variant="h3" component="h1" gutterBottom>
          Smart Membership
        </Typography>
        <Typography variant="h6" gutterBottom>
          Get access to exclusive book summaries and high priority service!
        </Typography>
        <Typography variant="body1">
          By purchasing a Smart Membership, you'll help us create better content and support our mission to share knowledge. Enjoy the benefits of a premium membership while contributing to our community.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          size="large"
          sx={{ marginTop: 3 }}
        >
          Buy Smart Membership
        </Button>
      </Box>
    </Container>
  );
};

export default SmartMembership;
