// src/components/SummaryPage/SectionSelect.js

import React from 'react';
import { FormControl, MenuItem, Select, Box, useTheme } from '@mui/material';

const SectionSelect = ({ sectionKeys, currentSectionIndex, handleSectionChange }) => {
  const theme = useTheme();

  const handleChange = (event) => {
    handleSectionChange(event.target.value);
  };

  return (
    <Box sx={{ marginBottom: 2 }}>
      <FormControl fullWidth variant="outlined">
        <Select
          value={currentSectionIndex}
          onChange={handleChange}
          displayEmpty
          inputProps={{ 'aria-label': 'section-selector' }}
          sx={{
            background: theme.palette.background.paper,
            borderRadius: '4px',
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.divider,
            },
            '& .MuiSelect-icon': {
              color: theme.palette.text.primary,
            },
            '& .MuiSelect-select:focus': {
              background: theme.palette.background.paper,
            },
            '& .MuiSelect-select.Mui-focused': {
              background: theme.palette.background.paper,
            },
          }}
          MenuProps={{
            transitionDuration: 400,
            transitionTimingFunction: 'ease-in',
          }}
        >
          {sectionKeys.map((title, index) => (
            <MenuItem key={title} value={index}>
              {title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default SectionSelect;
