// src/components/SummaryPage/SectionNavigation.js

import React from 'react';
import { IconButton, Box } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const SectionNavigation = ({
  onPreviousSection,
  onNextSection,
  isFirstSection,
  isLastSection,
}) => {
  return (
    <Box
      className="section-navigation"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      my={2}
    >
      <IconButton
        onClick={onPreviousSection}
        disabled={isFirstSection}
      >
        <ArrowBackIcon />
      </IconButton>
      <IconButton
        onClick={onNextSection}
        disabled={isLastSection}
        edge="end"
        sx={{
          position: 'absolute',
          left: '50%',
          transform: 'translateX(-50%)',
          padding: '1.5rem',
        }}
      >
        <ArrowForwardIcon />
      </IconButton>
    </Box>
  );
};

export default SectionNavigation;
