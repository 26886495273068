// src/components/Book/BookCover.js
import React, { useEffect, useState } from 'react';
import { CardMedia } from '@mui/material';
import Shimmer from '../Shimmer/Shimmer';

const BookCover = ({ isbn, title, cover }) => {
  const [bookCoverUrl, setBookCoverUrl] = useState('');
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    if (cover) {
      setBookCoverUrl(cover);
    } else if (isbn) {
      setBookCoverUrl(`https://covers.openlibrary.org/b/isbn/${isbn}-M.jpg`);
    }
  }, [isbn, cover]);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  return (
    <div className="book-cover-wrapper">
      {!imageLoaded && <Shimmer width="120px" height="180px" />}
      <CardMedia
        className="book-cover"
        component="img"
        height="180"
        image={bookCoverUrl}
        alt={title}
        onLoad={handleImageLoad}
        style={{ display: imageLoaded ? 'block' : 'none' }}
      />
    </div>
  );
};

export default BookCover;
