import React from 'react';
import { TextField } from '@mui/material';
import './SearchBar.css';

const SearchBar = ({ searchValue, setSearchValue }) => {
  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  return (
    <div className="search-bar">
      <TextField
        className="search-field"
        label="Search Books"
        variant="outlined"
        value={searchValue}
        onChange={handleSearch}
        fullWidth
      />
    </div>
  );
};

export default SearchBar;
