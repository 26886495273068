// src/components/LoginForm/LoginForm.js

import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import useLoginForm from '../../hooks/useLoginForm';
import FormInput from '../FormInput/FormInput';

const LoginForm = ({ onLogin }) => {
  const { values, errors, handleChange, handleSubmit } = useLoginForm(() => {
    onLogin(values.username, values.password);
  });

  return (
    <>
      <Typography variant="h4" gutterBottom>
        Login
      </Typography>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <FormInput
          label="Username"
          type="text"
          name="username"
          value={values.username}
          error={errors.username}
          onChange={handleChange}
        />
        <FormInput
          label="Password"
          type="password"
          name="password"
          value={values.password}
          error={errors.password}
          onChange={handleChange}
        />
        <Button variant="contained" type="submit">
          Login
        </Button>
      </Box>
    </>
  );
};

export default LoginForm;
