// src/components/SummaryPage/SummarySection.js

import React from 'react';
import { Paper } from '@mui/material';
import SummaryContent from './SummaryContent';

const SummarySection = ({ title, content, show }) => {
  return (
    <Paper elevation={2} sx={{ padding: 2, marginBottom: 2 }}>
      <SummaryContent sectionTitle={title} sectionContent={content} show={show} />
    </Paper>
  );
};

export default SummarySection;
