// src/components/Login/LoginPage.js

import React from 'react';
import { Box, Paper, Slide } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import LoginForm from '../../components/LoginForm/LoginForm'

const LoginPage = ({ onLogin }) => {
  const navigate = useNavigate();

  const handleLogin = (username, password) => {
    onLogin(username, password);
    navigate('/');
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        background: (theme) =>
          `linear-gradient(135deg, ${theme.palette.primary.main} 20%, ${theme.palette.primary.light} 100%)`,
      }}
    >
      <Slide direction="up" in={true} mountOnEnter unmountOnExit>
        <Paper
          elevation={4}
          sx={{
            padding: 4,
            width: { xs: '90%', sm: '50%', md: '30%' },
            borderRadius: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: '-10vh',
          }}
        >
          <LoginForm onLogin={handleLogin} />
        </Paper>
      </Slide>
    </Box>
  );
};

export default LoginPage;
