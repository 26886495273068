import React from 'react';
import { AppBar, Toolbar, Box, IconButton } from '@mui/material';
import { Link as NavLink } from 'react-router-dom';
import YouTubeIcon from '@mui/icons-material/YouTube';
import DarkModeToggle from './DarkModeToggle';
import logo from '../../assets/logo.png'; // Change this to the path of your logo file

const Header = ({ onToggleDarkMode, darkMode, loggedIn, onLogout }) => {
  return (
    <AppBar position="static">
      <Toolbar>
        <NavLink to="/">
          <img src={logo} alt="logo" style={{ maxHeight: '5em', marginBottom: '-20px' }} /> {/* Increase the size of the Logo and make it come out of the header */}
        </NavLink>
        {/* Add some space between the logo and the YouTube icon */}
        <Box sx={{ flexGrow: 1 }} />
        <IconButton
          color="inherit"
          aria-label="youtube"
          component="a"
          href="https://www.youtube.com/@intellectCorner"
          target="_blank"
          rel="noopener noreferrer"
        >
          <YouTubeIcon style={{ fontSize: 50 }} />
        </IconButton>
        <Box>
          <DarkModeToggle onToggleDarkMode={onToggleDarkMode} darkMode={darkMode} />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
