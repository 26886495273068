// src/components/Profile/countryCodes.js
const countryCodes = [
    { code: '+1', name: 'US' },
    { code: '+91', name: 'IN' },
    { code: '+86', name: 'CN' },
    { code: '+62', name: 'ID' },
    { code: '+55', name: 'BR' },
    { code: '+92', name: 'PK' },
    { code: '+880', name: 'BD' },
    { code: '+234', name: 'NG' },
    { code: '+7', name: 'RU' },
    { code: '+81', name: 'JP' },
    { code: '+63', name: 'PH' },
    { code: '+84', name: 'VN' },
    { code: '+90', name: 'TR' },
    { code: '+98', name: 'IR' },
    { code: '+52', name: 'MX' },
    { code: '+27', name: 'ZA' },
    { code: '+82', name: 'KR' },
    { code: '+380', name: 'UA' },
    { code: '+20', name: 'EG' },
    { code: '+39', name: 'IT' },
    { code: '+44', name: 'UK' },
  ];
  
  export default countryCodes;
  