// src/components/About/About.js

import React from 'react';
import { Typography } from '@mui/material';

const About = () => {
  return (
    <div>
      <Typography variant="h3" component="h1">About Us</Typography>
      <Typography variant="body1">
        We are a team of passionate book lovers dedicated to providing concise and informative book summaries for our users. Our goal is to make it easier for everyone to discover and enjoy great books.
      </Typography>
    </div>
  );
};

export default About;
